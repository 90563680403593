export const emptyScore = () => {
    sessionStorage.removeItem('estroke-scoreboard');
};
export const getScoreS = () => sessionStorage.getItem('estroke-scoreboard');

export const setScoreS = (obj) => {
    sessionStorage.setItem('estroke-scoreboard', JSON.stringify(obj));
};
export const emptyVitalia = () => {
    sessionStorage.removeItem('estroke-vitalia');
};
export const getVitaliaS = () => sessionStorage.getItem('estroke-vitalia');

export const setVitaliaS = (obj) => {
    sessionStorage.setItem('estroke-vitalia', JSON.stringify(obj));
};
export const emptyMedicine = () => {
    sessionStorage.removeItem('estroke-medicine');
};
export const getMedicineS = () => sessionStorage.getItem('estroke-medicine');

export const setMedicineS = (obj) => {
    sessionStorage.setItem('estroke-medicine', JSON.stringify(obj));
};
export const emptyCaseInfo = () => {
    sessionStorage.removeItem('estroke-case-info');
};
export const getCaseInfoS = () => sessionStorage.getItem('estroke-case-info');

export const setCaseInfoS = (obj) => {
    sessionStorage.setItem('estroke-case-info', JSON.stringify(obj));
};
export const emptyHistory = () => {
    sessionStorage.removeItem('history');
};
export const setVitaliaBS = (obj) => {
    sessionStorage.setItem('estroke-bloodsugar', JSON.stringify(obj));
};
export const emptyBloodSugar = () => {
    sessionStorage.removeItem('estroke-bloodsugar');
};
export const getBloodSugar = () => sessionStorage.getItem('estroke-bloodsugar');
export const setVitaliaTemp = (obj) => {
    sessionStorage.setItem('estroke-temp', JSON.stringify(obj));
};
export const emptyTemp = () => {
    sessionStorage.removeItem('estroke-temp');
};
export const getTemp = () => sessionStorage.getItem('estroke-temp');
export const setVitaliaPulse = (obj) => {
    sessionStorage.setItem('estroke-pulse', JSON.stringify(obj));
};
export const emptyPulse = () => {
    sessionStorage.removeItem('estroke-pulse');
};
export const getPulse = () => sessionStorage.getItem('estroke-pulse');
export const setGenderS = (obj) => {
    sessionStorage.setItem('estroke-gender', JSON.stringify(obj));
};
export const emptyGender = () => {
    sessionStorage.removeItem('estroke-gender');
};
export const getGender = () => sessionStorage.getItem('estroke-gender');
export const setAgeS = (obj) => {
    sessionStorage.setItem('estroke-age', JSON.stringify(obj));
};
export const emptyAge = () => {
    sessionStorage.removeItem('estroke-age');
};
export const getAge = () => sessionStorage.getItem('estroke-age');

export const getVersionS = () => sessionStorage.getItem('estroke-version');
export const setVersionS = (version) => {
    sessionStorage.setItem('estroke-version', version);
};
export const emptyVersionS = () => {
    sessionStorage.removeItem('estroke-version');
};

export const emptySession = () => {
    nyPasient();
    emptyVersionS();
};
export const nyPasient = () => {
    emptyScore();
    emptyVitalia();
    emptyMedicine();
    emptyCaseInfo();
    emptyHistory();
    emptyBloodSugar();
    emptyTemp();
    emptyPulse();
    emptyAge();
    emptyGender();
};
