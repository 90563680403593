import React from 'react';
import './NyPasient.scss';
import { nyPasient } from '../variables/sessionVariables';

const NyPasient = (props) => {
    const { toNext, caseInfo, changeCaseInfo, t } = props;

    const onChange = () => {
        nyPasient();
        changeCaseInfo({ ...caseInfo, startDateTime: new Date() });
        toNext();
    };

    return (
        <div className='NyPasient'>
            <h1>{t('web.general.title')}</h1>
            <button className='start-new-test' onClick={onChange}>
                <img src='images/btn_circle_doctortraining_down.png' alt='' />
                <span>{t('web.steps.btns.newPatient')}</span>
            </button>
        </div>
    );
};

export default NyPasient;
