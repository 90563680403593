import React from 'react';
import './StartPage.scss';
import { emptySession } from '../variables/sessionVariables';

const StartPage = (props) => {
    const { toNext, updateVersion, t } = props;
    const onChange = (e) => {
        emptySession();
        updateVersion(e.currentTarget.getAttribute('version'));
        toNext();
    };

    return (
        <div className='StartPage'>
            <h1>{t('web.general.title')}</h1>
            <p>{t('web.general.subtitle')}</p>
            <div className='versionSelection'>
                <button
                    className='mobileversion'
                    version='mobile'
                    onClick={onChange}
                >
                    <img src='images/estroke.png' alt='Mobil-app' />
                    <span>{t('web.general.mobileApp')}</span>
                </button>

                <button className='webversion' version='web' onClick={onChange}>
                    <img src='images/ic_snla_logo.svg' alt='Web-app' />
                    <span>{t('web.general.webApp')}</span>
                </button>
            </div>
            <div className='footer'>
                <p>
                    <a
                        href='https://norskluftambulanse.no/nyheter/estroke-slagkraftig-app/'
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        {t('web.general.footerText')}
                    </a>
                </p>
                <p>
                    <a href='mailto:support@estroke.no'>
                        {t('web.general.footerEmail')}
                    </a>
                </p>
            </div>
        </div>
    );
};

export default StartPage;
