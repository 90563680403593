import React, { useState, useEffect } from 'react';
import InputMultipleSelect from '../InputMultipleSelect/InputMultipleSelect';
import InputSwitch from '../InputSwitch/InputSwitch';
import './LegemidlerPage.scss';
import { InputFullDate } from '../InputDateFields/InputDateFields';
import InputTextField from '../InputTextField/InputTextField';

const LegemidlerPage = (props) => {
    const { medicine, legemidler, changeLegemidler, toNext, t } = props;
    const { inntakUnknown, inntakTime, hasNote, note } = medicine;
    const [medsWithCheck, setMedsWithCheck] = useState([]);

    useEffect(() => {
        if (medsWithCheck.length < 1) {
            setMedsWithCheck(
                legemidler.map((lm) => ({
                    ...lm,
                    checked: false,
                    disabled: false
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legemidler]);

    const changeContent = (objName, newVal) => {
        let newMedsList = { ...medicine };
        newMedsList[objName] = newVal;
        changeLegemidler(newMedsList);
    };
    const changeMeds = (newMeds) => {
        const othersChecked = newMeds.find((elm) => elm.name === 'Others');
        const checkedNone = newMeds.find((elm) => elm.name === 'None');
        const checkedUnknown = newMeds.find((elm) => elm.name === 'Unknown');
        const disabledMaps = newMeds.map((mp) => mp.disabled);

        let medSet = [...newMeds];

        if (checkedNone && checkedNone.checked) {
            medSet = newMeds.map((med) => {
                const mdNameNone = med.name === 'None';
                return {
                    ...med,
                    disabled: mdNameNone ? false : true,
                    checked: !mdNameNone ? false : med.checked
                };
            });
            setMedsWithCheck(medSet);
        } else if (checkedUnknown && checkedUnknown.checked) {
            medSet = newMeds.map((med) => {
                const mdNameNone = med.name === 'Unknown';
                return {
                    ...med,
                    disabled: mdNameNone ? false : true,
                    checked: !mdNameNone ? false : med.checked
                };
            });
            setMedsWithCheck(medSet);
        } else if (
            !checkedUnknown.checked &&
            !checkedNone.checked &&
            disabledMaps.length > 0
        ) {
            medSet = newMeds.map((med) => {
                return { ...med, disabled: false };
            });
            setMedsWithCheck(medSet);
        } else setMedsWithCheck(medSet);

        const toMedicine = medSet.filter((itm) => itm.checked);
        if (othersChecked && othersChecked.checked) {
            changeLegemidler({ ...medicine, meds: toMedicine, hasNote: true });
        } else if (
            hasNote &&
            othersChecked &&
            othersChecked.checked === false
        ) {
            changeLegemidler({ ...medicine, meds: toMedicine, hasNote: false });
        } else {
            changeContent('meds', toMedicine);
        }
    };

    const changeCheck = (checkVal) => changeContent('inntakUnknown', checkVal);
    const changeTime = (newTime) => changeContent('inntakTime', newTime);
    const changeNote = (noteVal) => changeContent('note', noteVal);

    return (
        <div className='LegemidlerPage'>
            <h1>{t('form.medication.title')}</h1>
            {medsWithCheck && medsWithCheck.length > 0 && (
                <div className='scrollarea-45'>
                    <InputMultipleSelect
                        areaName='Legemidler'
                        showAreaName={false}
                        options={medsWithCheck}
                        changeOptions={changeMeds}
                    />
                    <InputTextField
                        value={note}
                        onChange={changeNote}
                        lbl={t('web.steps.medication.other_medication')}
                        disabled={!hasNote}
                        name='note-input-meds'
                    />
                </div>
            )}
            <h2 className='legemiddelheader'>
                {t('form.medication.intake_time')}
            </h2>
            <InputFullDate
                inDate={inntakTime}
                changeDate={changeTime}
                disabled={inntakUnknown}
                t={t}
            />
            <InputSwitch
                checked={inntakUnknown}
                setChecked={changeCheck}
                lbl={t('form.medication.intake_unknown')}
                name='birthnounknown'
            />
            {medicine.meds.length === 0 && (
                <p className='disable-txt-next'>
                    {t('web.steps.medication.demo_txt')}
                </p>
            )}
            <button
                className='next-btn'
                onClick={toNext}
                disabled={medicine.meds.length === 0}
            >
                <span>{t('button.next')}</span>
            </button>
        </div>
    );
};

export default LegemidlerPage;
