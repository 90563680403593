import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import StartPage from './StartPage/StartPage';
import NyPasient from './NyPasient/NyPasient';
import IdPerson from './IdPerson/IdPerson';
import SymptomDebut from './SymptomDebut/SymptomDebut';
import LegemidlerPage from './LegemidlerPage/LegemidlerPage';
import Pasient from './Pasient/Pasient';
import VitaliaPage from './VitaliaPage/VitaliaPage';
import NihssStep from './NihssStep/NihssStep';
import Summary from './Summary/Summary';
import { doEncode } from './generalFunctions';
import { dateValWithhours } from './variables/dateVariables';
import { stepArr, stepObjName } from './variables/generalVariables';
import { legemidler } from './variables/inputVariables';
import {
    setVitaliaS,
    getVitaliaS,
    setScoreS,
    getScoreS,
    getMedicineS,
    setMedicineS,
    getCaseInfoS,
    setCaseInfoS,
    getGender,
    setGenderS,
    getAge,
    setAgeS,
    setVitaliaBS,
    setVitaliaTemp,
    setVitaliaPulse,
    getBloodSugar,
    getTemp,
    getPulse,
    getVersionS,
    setVersionS
} from './variables/sessionVariables';
import './App.scss';

// Define constants for the initial state values
const initialMedicineState = {
    meds: [],
    inntakUnknown: false,
    inntakTime: new Date(),
    hasNote: false,
    note: ''
};

const initialVitaliaState = {
    symptomDebut: new Date(),
    lbl: 'Blodtrykk ukjent.',
    unknownDebut: false,
    sbValidated: false,
    bpSystol: 120,
    bpDiast: 80,
    known: false
};

const initialStartCaseInfoState = {
    birthNo: '',
    unknownBirthNo: false,
    aId: '',
    startDateTime: null,
    validOk: false
};

const initialAgeState = '60 år';

const initialBloodSugarState = {
    lbl: 'Blodsukker ukjent.',
    left: 5,
    right: 0,
    known: false
};

const initialTempState = {
    lbl: 'Temperatur ukjent.',
    left: 37,
    right: 0,
    known: false
};

const initialPulseState = {
    lbl: 'Puls ukjent.',
    value: 60,
    known: false
};

function App() {
    const { t } = useTranslation();
    let history = useHistory();
    const [version, setVersion] = useState('');
    const [scoreBoard, setScoreBoard] = useState({});
    const stepArray = stepArr(t);
    const medicines = legemidler(t);
    const [medicine, setMedicine] = useState(initialMedicineState);
    const [vitalia, setVitalia] = useState(initialVitaliaState);
    const [loadVitaliaFields, setLoadVitaliaFields] = useState(false);
    const [startCaseInfo, setStartCaseInfo] = useState(
        initialStartCaseInfoState
    );
    const [gender, setGender] = useState('');
    const [age, setAge] = useState(initialAgeState);
    const [bloodSugar, setBloodSugar] = useState(initialBloodSugarState);
    const [temp, setTemp] = useState(initialTempState);
    const [pulse, setPulse] = useState(initialPulseState);

    const pushToHistory = (val) => {
        history.push(val);
    };

    useEffect(() => {
        let version = getVersionS();
        if (version) {
            updateVersion(version);
        } else {
            updateVersion(null);
        }

        let hasScoreBoard = getScoreS();
        if (hasScoreBoard) {
            setScoreBoard(JSON.parse(hasScoreBoard));
        }

        let hasVitalia = getVitaliaS();
        if (hasVitalia) {
            let jsonVitalia = JSON.parse(hasVitalia);
            let newDebut = new Date(jsonVitalia.symptomDebut);
            jsonVitalia = {
                ...vitalia,
                ...jsonVitalia,
                symptomDebut: newDebut
            };
            setVitalia(jsonVitalia);
        }
        setLoadVitaliaFields(true);

        let hasMedicine = getMedicineS();
        if (hasMedicine) {
            let jsonMedicine = JSON.parse(hasMedicine);
            let newInntakTime = new Date(jsonMedicine.inntakTime);
            jsonMedicine = {
                ...medicine,
                ...jsonMedicine,
                inntakTime: newInntakTime
            };
            setMedicine(jsonMedicine);
        }

        let hasCaseInfo = getCaseInfoS();
        if (hasCaseInfo) {
            let jParse = JSON.parse(hasCaseInfo);
            let newStartTime = new Date(jParse.startDateTime);
            jParse = {
                ...startCaseInfo,
                ...jParse,
                startDateTime: newStartTime
            };
            setStartCaseInfo(jParse);
        }

        let hasGender = getGender();
        if (hasGender) {
            setGender(JSON.parse(hasGender));
        }

        let hasAge = getAge();
        if (hasAge) {
            setAge(JSON.parse(hasAge));
        }

        let hasBS = getBloodSugar();
        if (hasBS) {
            setBloodSugar(JSON.parse(hasBS));
        }

        let hasTemp = getTemp();
        if (hasTemp) {
            setTemp(JSON.parse(hasTemp));
        }

        let hasPulse = getPulse();
        if (hasPulse) {
            setPulse(JSON.parse(hasPulse));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateVersion = (content) => {
        resetState();
        setVersion(content);
        setVersionS(content);
    };
    const updateScore = (itm, score) => {
        let updatedScore = { ...scoreBoard };
        updatedScore[itm] = score;
        setScoreBoard(updatedScore);
        setScoreS(updatedScore);
    };
    const updateVitalia = (itm, update) => {
        let updatedVitalia = { ...vitalia };
        updatedVitalia[itm] = update;
        setVitalia(updatedVitalia);
        updatedVitalia = {
            ...updatedVitalia,
            symptomDebut: dateValWithhours(updatedVitalia.symptomDebut)
        };
        setVitaliaS(updatedVitalia);
    };
    const updateMedicine = (content) => {
        setMedicine(content);
        setMedicineS(content);
    };
    const updateCaseInfo = (content) => {
        resetState(false);
        setStartCaseInfo(content);
        setCaseInfoS(content);
    };
    const updateGender = (content) => {
        setGender(content);
        setGenderS(content);
    };
    const updateAge = (content) => {
        setAge(content);
        setAgeS(content);
    };
    const updateBloodSugar = (itm, update) => {
        let updatedBS = { ...bloodSugar };
        updatedBS[itm] = update;
        setBloodSugar(updatedBS);
        setVitaliaBS(updatedBS);
    };
    const updateTemp = (itm, update) => {
        let updatedTemp = { ...temp };
        updatedTemp[itm] = update;
        setTemp(updatedTemp);
        setVitaliaTemp(updatedTemp);
    };
    const updatePulse = (itm, update) => {
        let updatedPulse = { ...pulse };
        updatedPulse[itm] = update;
        setPulse(updatedPulse);
        setVitaliaPulse(updatedPulse);
    };

    function resetState(resetVersion = true) {
        if (resetVersion) setVersion('');
        setScoreBoard({});
        setMedicine(initialMedicineState);
        setVitalia(initialVitaliaState);
        setLoadVitaliaFields(true);
        setStartCaseInfo(initialStartCaseInfoState);
        setGender('');
        setAge(initialAgeState);
        setBloodSugar(initialBloodSugarState);
        setTemp(initialTempState);
        setPulse(initialPulseState);
    }

    return (
        <div className='App'>
            <Header
                history={history}
                goToPage={pushToHistory}
                scoreBoard={scoreBoard}
                startCaseInfo={startCaseInfo}
                vitalia={vitalia}
                version={version}
                t={t}
            />
            <main>
                <Switch>
                    <Route
                        path='/'
                        render={() => (
                            <StartPage
                                toNext={() => pushToHistory('/nypasient')}
                                updateVersion={updateVersion}
                                t={t}
                            />
                        )}
                        exact
                    />
                    <Route
                        path='/nypasient'
                        render={() => (
                            <NyPasient
                                toNext={() =>
                                    pushToHistory(
                                        version === 'mobile'
                                            ? '/symptomdebut'
                                            : '/identifiser'
                                    )
                                }
                                caseInfo={startCaseInfo}
                                changeCaseInfo={updateCaseInfo}
                                t={t}
                            />
                        )}
                        exact
                    />
                    <Route
                        path='/identifiser'
                        render={() => (
                            <IdPerson
                                caseInfo={startCaseInfo}
                                changeCaseInfo={updateCaseInfo}
                                toNext={pushToHistory}
                                t={t}
                            />
                        )}
                    />
                    <Route
                        path='/symptomdebut'
                        render={() => (
                            <SymptomDebut
                                nextUrl={'/medikamenter'}
                                loadFields={loadVitaliaFields}
                                vitalia={vitalia}
                                setVitalia={updateVitalia}
                                goToPage={pushToHistory}
                                t={t}
                            />
                        )}
                    />
                    <Route
                        path='/medikamenter'
                        render={() => (
                            <LegemidlerPage
                                medicine={medicine}
                                legemidler={medicines}
                                changeLegemidler={updateMedicine}
                                toNext={() => pushToHistory('/pasient')}
                                t={t}
                            />
                        )}
                    />
                    <Route
                        path='/pasient'
                        render={() => (
                            <Pasient
                                gender={gender}
                                setGender={updateGender}
                                toNext={() =>
                                    pushToHistory(
                                        `/${doEncode(
                                            stepObjName(stepArray[0] || '')
                                        )}`
                                    )
                                }
                                t={t}
                            />
                        )}
                    />
                    {stepArray.map((step, ind) => {
                        return (
                            <Route
                                key={ind}
                                path={`/${doEncode(stepObjName(step))}`}
                                render={() => (
                                    <NihssStep
                                        stepObj={step}
                                        prevObj={
                                            ind === 0
                                                ? { urlName: 'symptomdebut' }
                                                : stepArray[ind - 1]
                                        }
                                        nextObj={stepArray[ind + 1]}
                                        updateScore={updateScore}
                                        goToPage={pushToHistory}
                                        age={age}
                                        setAge={updateAge}
                                        t={t}
                                    />
                                )}
                            />
                        );
                    })}
                    <Route
                        path='/vitalia'
                        render={() => (
                            <VitaliaPage
                                vitalia={vitalia}
                                setVitalia={updateVitalia}
                                toNext={() => pushToHistory('/oppsummering')}
                                bloodSugar={bloodSugar}
                                setBloodSugar={updateBloodSugar}
                                temp={temp}
                                setTemp={updateTemp}
                                pulse={pulse}
                                setPulse={updatePulse}
                                t={t}
                            />
                        )}
                    />
                    <Route
                        path='/oppsummering'
                        render={() => (
                            <Summary
                                version={version}
                                scoreBoard={scoreBoard}
                                vitalia={vitalia}
                                caseInfo={startCaseInfo}
                                medicine={medicine}
                                gender={gender}
                                age={age}
                                bloodSugar={bloodSugar}
                                temp={temp}
                                pulse={pulse}
                                goToPage={pushToHistory}
                                t={t}
                            />
                        )}
                    />
                </Switch>
            </main>
        </div>
    );
}

export default App;
