import React, { useState, useEffect, Fragment } from 'react';
import { emptySession } from '../variables/sessionVariables';
import { ShowTimeFromDate } from '../generalFunctions';
import { dateValForApi } from '../variables/dateVariables';
//import { saveChangesStroke } from '../variables/apifunctions';
import { dateValHrsView } from '../variables/dateVariables';
import './Summary.scss';

const Summary = (props) => {
    const {
        scoreBoard,
        vitalia,
        caseInfo,
        medicine,
        gender,
        age,
        bloodSugar,
        temp,
        pulse,
        goToPage,
        t
    } = props;
    const [totalScore, setTotalScore] = useState(0);
    const [mappedVals, setMappedVals] = useState([]);
    const [selectedMeds, setSelecetedMeds] = useState([]);
    const [showScoreImg, setShowScoreImg] = useState(true);

    useEffect(() => {
        if (medicine && medicine.meds) {
            setSelecetedMeds(medicine.meds.filter((md) => md.checked));
        }
    }, [medicine]);

    useEffect(() => {
        const sVal = Object.values(scoreBoard).reduce(
            (total, obj) => total + (obj.score || 0),
            0
        );
        setTotalScore(sVal);

        let sortable = [];
        for (let score in scoreBoard) {
            if (scoreBoard[score])
                sortable.push({ ...scoreBoard[score], val: score });
        }
        sortable.sort((a, b) => {
            return b.score - a.score;
        });
        setMappedVals(sortable);
    }, [scoreBoard]);

    const sendToApi = (e) => {
        const sendbody = {
            NihssEntry: scoreBoard,
            StartDateTime: dateValForApi(vitalia.symptomDebut)
        };
        if (caseInfo && caseInfo.CaseId) sendbody['CaseId'] = caseInfo.CaseId;
        //saveChangesStroke(sendbody);
        // if (getVersionS() === 'web') {
        //     alert('Kun test - ingen PDF laget');
        // } else {
        //     alert('Kun test - ingen sms er sendt');
        // }
        emptySession();
        goToPage('/');
    };

    const toggleScoreImg = () => {
        setShowScoreImg(showScoreImg ? false : true);
    };

    return (
        <div
            className={`summary-page ${
                showScoreImg ? 'showScoreImg' : 'hideScoreImg'
            }`}
        >
            <h1>{t('web.summary.title')}</h1>
            <div className='score-sum'>
                <img
                    src={`images/Oppsummering.svg`}
                    alt=''
                    className='bck-img'
                />
                <h2>{totalScore || ''}</h2>
                <p style={{ paddingTop: '15px', fontSize: '15px' }}>NIHSS</p>
                <div className='left-float'>
                    <h3>{t('form.symptoms.title')}</h3>
                    <p className='txt-small'>{t('form.symptoms.time_since')}</p>
                </div>
                <ShowTimeFromDate
                    fromDate={vitalia.symptomDebut}
                    isUnknown={vitalia.unknownDebut}
                    t={t}
                />
            </div>
            <ul className='summary-list-info list-medicinfo'>
                <li className='li-el-medicines'>
                    <span className='left'>{gender + ' ' + age}</span>
                </li>
            </ul>
            <ul className='summary-list-info list-medicinfo'>
                <li className='li-el-medicines'>
                    <span className='left'>{t('form.medication.title')}</span>
                    <div className='right'>
                        {selectedMeds.length > 0 ? (
                            <Fragment>
                                {selectedMeds.map((med) => {
                                    return <span key={med.lbl}>{med.lbl}</span>;
                                })}
                            </Fragment>
                        ) : (
                            t('form.medication.none')
                        )}
                    </div>
                </li>
                {medicine.inntakUnknown === false && (
                    <li className='li-el-medicines'>
                        <span className='left'>
                            {t('web.summary.medication.intake_time')}
                        </span>
                        <span className='right bold'>
                            {dateValHrsView(medicine.inntakTime)}
                        </span>
                    </li>
                )}
            </ul>
            <h2 className='grey-heading-section'>VITALIA</h2>
            <ul className='summary-list-info list-vitalia'>
                <li>
                    <span className='left'>
                        {t('form.vitals.blood_pressure')}
                    </span>
                    <span className='right'>
                        {vitalia.known
                            ? `${vitalia.bpSystol}/${vitalia.bpDiast} mmHg`
                            : t('general.unknown')}
                    </span>
                </li>
                <li>
                    <span className='left'>
                        {t('form.vitals.blood_sugar')}Blodsukker
                    </span>
                    <span className='right'>
                        {bloodSugar.known
                            ? `${bloodSugar.left}.${bloodSugar.right} mmol/l`
                            : t('general.unknown')}
                    </span>
                </li>
                <li>
                    <span className='left'>
                        {t('form.vitals.temperature')}Temperatur
                    </span>
                    <span className='right'>
                        {temp.known
                            ? `${temp.left}.${temp.right} °C`
                            : t('general.unknown')}
                    </span>
                </li>
                <li>
                    <span className='left'>{t('form.vitals.pulse')}Pulse</span>
                    <span className='right'>
                        {pulse.known
                            ? `${pulse.value} / min`
                            : t('general.unknown')}
                    </span>
                </li>
            </ul>
            {mappedVals && mappedVals.length > 0 ? (
                <>
                    <p className='grey-heading-section'>
                        <button
                            className='toggleScoreImg'
                            onClick={toggleScoreImg}
                        >
                            {t('web.summary.show_hide_img')}
                        </button>
                    </p>

                    <ul className='summary-list'>
                        {mappedVals.map((obj) => {
                            return (
                                <React.Fragment key={obj.val}>
                                    {obj.score > 0 && (
                                        <li key={obj.val}>
                                            <img
                                                src={`images/${obj.img}.svg`}
                                                alt=''
                                            />
                                            <span className='origin-sum'>
                                                {obj.title || obj.val}:
                                            </span>
                                            <span className='score-number red-txt'>
                                                {obj.score}
                                                {obj.direction === 'left' &&
                                                    '-v'}
                                                {obj.direction === 'right' &&
                                                    '-h'}
                                            </span>
                                        </li>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </>
            ) : (
                <p>{t('web.summary.error_msg.no_data')}</p>
            )}
            <button type='button' className='next-btn' onClick={sendToApi}>
                {t('web.summary.btns.end_survey')}
            </button>
        </div>
    );
};

export default Summary;
